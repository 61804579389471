import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { CardAddComponent } from './pages/card-add/card-add.component';
import { CardListComponent } from './pages/card-list/card-list.component';
import { CardImportComponent } from './pages/card-import/card-import.component';

const routes: Routes = [
  { path: 'add', component: CardAddComponent },
  { path: 'list', component: CardListComponent },
  { path: 'edit/:id', component: CardAddComponent },
  { path: 'lot-import', component: CardImportComponent },
  { path: '', redirectTo: 'list', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CardsMgmtRoutingModule { }
