<!-- NOTE form -->
<form [formGroup]="formGroup" autocomplete="on">

  <h5 class="title nk-block-title">Paso 1: Selección de fichero</h5>

  <div class="row gy-4">

    <div class="col-md-7">
      <div class="form-group">
        <label class="form-label" for="multipartFile">Fichero <span class="form-required">*</span></label>
        <div class="form-control-wrap">
          <input type="file" class="form-control" #multipartFile id="multipartFile" placeholder="Fichero a importar"
            (change)="onFileSelected()">
          <div class="invalid-feedback" *ngIf="formGroup.controls['multipartFile'].hasError('required')">
            {{ 'FIELD_REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div><!--col-->

  </div><!--row-->

  <div class="row gy-4 pt-4">
    <div class="col">

      <div class="d-flex justify-content-between">
        <button class="btn btn-primary" (click)="onClickImport();"
          [disabled]="formGroup.pending || formGroup.invalid || this.storeService.getIsLoading()">
          <span>Importar</span>
          <em class="icon ni ni-upload"></em>
        </button>
      </div>

    </div>
  </div><!--row-->

</form>
