import { Exportable } from "@models/exporter.model";
import { Card } from "./card.model";

export class CardImportResult implements Exportable {
  cardsImported: Card[] = [];
  pendingImports: PendingImport[] = [];
}

interface PendingImport {
  serial: string;
  uuid: string;
  warnings: string[];
  errors: string[];

}
