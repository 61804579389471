import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Internal Modules
import { SharedModule } from 'src/app/shared/shared.module';
import { CardsMgmtRoutingModule } from './cards-mgmt-routing.module';

// Components
import { CardAddComponent } from './pages/card-add/card-add.component';
import { CardListComponent } from './pages/card-list/card-list.component';
import CardQuickviewComponent from './pages/card-quickview/card-quickview.component';
import { CardImportComponent } from './pages/card-import/card-import.component';
import { Step1CardImportComponent } from './components/step1-card-import/step1-card-import.component';
import { Step2CardImportComponent } from './components/step2-card-import/step2-card-import.component';
import { ResultCardImportQuickviewComponent } from './pages/result-card-import-quickview/result-card-import-quickview.component';


@NgModule({
  declarations: [
    CardAddComponent,
    CardListComponent,
    CardQuickviewComponent,

    CardImportComponent,
    Step1CardImportComponent,
    Step2CardImportComponent,
    ResultCardImportQuickviewComponent,
  ],
  imports: [
    CommonModule,

    // Internal Modules
    CardsMgmtRoutingModule,
    SharedModule
  ],
  exports: [
    CardListComponent,
    CardAddComponent,
  ]
})
export class CardsMgmtModule { }
