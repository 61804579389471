// Angular modules
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// External modules
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';

// Services
import { StoreService } from '@services/store.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { CustomerService } from '@customers/customers-mgmt/services/customer.service';
import { CardGroupService } from '@cards/card-groups/services/card-group.service';

// Models
import { Card } from '@cards/cards-mgmt/models/index';
import { CardService } from '@cards/cards-mgmt/services/card.service';
import { QueryParameter } from '@models/api-request.model';
import { UserViewDto } from '@security/models';

// Validators
import { UniqueCardCodeValidator } from '@cards/cards-mgmt/validators/unique-card-code-validator.service';

// Helpers
import { CardFormGroupHelper } from '@cards/cards-mgmt/helpers/card-form-configurer.helper';

// Base class
import { BaseAddComponent } from '@pages/base/base-add.component';
import { CustomAutocompleteComponent } from '@autocomplete/custom-autocomplete/custom-autocomplete.component';
import { EventBusService } from '@services/event-bus.service';
import { SearchStrategy } from '@enums/search-strategy.enum';


@Component({
  selector: 'lockbin-card-add',
  templateUrl: './card-add.component.html',
  styles: [],
})
export class CardAddComponent extends BaseAddComponent<Card> implements OnInit {

  @ViewChild('customAutocompleteCustomer') customAutocompleteCustomer!: CustomAutocompleteComponent;
  @ViewChild('customAutocompleteCardGroup') customAutocompleteCardGroup!: CustomAutocompleteComponent;


  searcherCustomer$!: Observable<any>; //searcher used in Autocomplete component
  searcherCardGroup$!: Observable<any>; //searcher used in Autocomplete component

  urlForQRCode = '';

  canCustomerBeEdited = false;

  // -------------------------------------------------------------------------------
  // NOTE Defined reactive form on Angular style ------------------------------------
  // -------------------------------------------------------------------------------
  protected override formGroup!: FormGroup<{

    code: FormControl<string>;
    uid: FormControl<string>;
    model: FormControl<string>;
    description: FormControl<string>;

    customerId: FormControl<number>;
    cardGroupId: FormControl<number>;

    customerName: FormControl<string>;
    cardGroupName: FormControl<string>;

  }>;


  // -------------------------------------------------------------------------------
  // #region NOTE Constructor & Init -----------------------------------------------
  // -------------------------------------------------------------------------------
  constructor(
    protected override location: Location,
    protected override titleService: Title,
    protected override storeService: StoreService,
    protected override router: Router,
    protected override logger: NGXLogger,
    protected override translateService: TranslateService,
    protected override activatedRoute: ActivatedRoute,
    protected override eventBusService: EventBusService,
    private cardService: CardService,
    private uniqueCardCodeValidator: UniqueCardCodeValidator,
    private customerService: CustomerService,
    private cardGroupService: CardGroupService
  ) {

    super(location, titleService, storeService, router, logger, translateService, activatedRoute, eventBusService);

  }


  override ngOnInit(): void {

    this.storeService.setIsLoading(true);

    // calculates if customer can be edited based on customerId attribute
    this.canCustomerBeEdited = this.calculateCustomerCanBeEdited();

    // setup searchers for autocompletes
    this.setupSearchers();

    // set routes to list/edit after page operation (add or edit) is done
    this.setupRoutesPostOperation();

    // setup FormGroup with validators, default values, etc.
    this.initFormGroup();

    // get params. Only are received when operation is edit
    this.activatedRoute.params
      .subscribe(params => {

        const id = params['id'];

        this.setupPageForAddOrEdit(id); //title, subtitle, and others

        if (id) { // is editing

          this.cardService.findById(id, SearchStrategy.EAGER)
            .subscribe((itemToUpdate: Card) => {

              // set entity value for update. If entity not exist (this shouldn't happen), initialize the variable to a new object
              this.entity = (itemToUpdate) ? itemToUpdate : new Card();

              this.urlForQRCode = this.cardService.getUrlForQRCode(this.entity);

              // reset form to default values from the 'this.entity' value
              this.setDefaultValuesToFormGroup();
              this.setTransientValuesToFormGroup();
              // TODO: Descomentar cuando se puedan realizar búsquedas por código
              // this.setAsyncValidators();

              // set values to autocomplete inputs fields and formGroup properties asociated with autocomplete
              this.setValuesToAutocompletes();

              this.storeService.setIsLoading(false);

            });
        } else { // is adding

          // set entity value for add
          this.entity = new Card();

          // reset form to default values from the 'this.entity' value
          this.setDefaultValuesToFormGroup();
          // TODO: Descomentar cuando se puedan realizar búsquedas por código
          // this.setAsyncValidators();

          this.storeService.setIsLoading(false);

        }
      })
  }


  private calculateCustomerCanBeEdited(): boolean {

    const userLogged: UserViewDto = this.storeService.getUser();

    if (userLogged && userLogged.idCustomer && userLogged.idCustomer > 0) return false;

    return true;
  }


  /**
 * Setups searchers to be used in automplete fields
 */
  setupSearchers() {
    const queryParams: QueryParameter = {};

    this.searcherCustomer$ = this.customerService.find(queryParams);
    this.searcherCardGroup$ = this.cardGroupService.find(queryParams);

  }


  /**
   * Setups page for add or edit based on id
   * @param id
   */
  private setupPageForAddOrEdit(id: any) {

    if (id) {
      this.operationTitle = 'Edición de Tarjeta'
      this.operationSubtitle = 'Modifique los datos de la tarjeta'
    } else {
      this.operationTitle = 'Nueva Tarjeta'
      this.operationSubtitle = 'Rellene los datos de la tarjeta'
    }

  }


  /**
   * Init formGroup with a helper
   */
  private initFormGroup() {
    this.formGroup = new CardFormGroupHelper().getCardFormGroup();
  }


  private setAsyncValidators() {

    this.formGroup.controls.code.addAsyncValidators
      (this.uniqueCardCodeValidator.validateUniqueCardNameValidator(this.entity.id))

  }

  /**
   * Set routes to list/edit after page operation (add or edit) is done
   */
  private setupRoutesPostOperation() {
    this.routeToEdit = '/tarjetas/gestion-tarjetas/edit/';
    this.routeToList = '/tarjetas/gestion-tarjetas/list/';
  }


  /**
 * Set values to autocomplete input field once the value has been received from service
 */
  private setValuesToAutocompletes() {
    if (this.canCustomerBeEdited) {
      if (this.entity.customer?.id > 0) {
        this.customAutocompleteCustomer.fieldValue = this.entity.customer?.name;
      }
    }

    if (this.entity.cardGroup?.id > 0) {
      this.customAutocompleteCardGroup.fieldValue = this.entity.cardGroup?.name;
    }

  }
  // #endregion --------------------------------------------------------------------
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Custom validations ----------------------------------------------------
  // -------------------------------------------------------------------------------
  /**
   * Evals if a card name exits.
   * Due to this page support add/edit operations, need to check entity id's with same 'name' for the update operation
   */
  public hasErrorDueToCardCodeExists(): boolean {

    return false;
    // return this.formGroup.controls.code.hasError('cardCodeExists')
    //   && !this.formGroup.controls.code.errors?.['entityIdsWithSameTerm']?.includes(this.entity.id)
  }
  // #endregion --------------------------------------------------------------------
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region NOTE Actions ----------------------------------------------------------
  // -------------------------------------------------------------------------------

  // set values to form when an item is selected
  doSelectItemAutocompleteCustomer(selectedItem: any) {

    this.logger.info('Seleccionado el..... --->', selectedItem);

    if (selectedItem?.item?.id) {
      this.formGroup.controls['customerId'].setValue(selectedItem.item.id);
      this.formGroup.controls['customerName'].setValue(selectedItem.item.name);
    }
  }

  doSelectItemAutocompleteCardGroup(selectedItem: any) {

    this.logger.info('Seleccionado el..... --->', selectedItem);

    if (selectedItem?.item?.id) {
      this.formGroup.controls['cardGroupId'].setValue(selectedItem.item.id);
      this.formGroup.controls['cardGroupName'].setValue(selectedItem.item.name);
    }
  }

  public async onClickSubmit(): Promise<void> {

    const titleText = this.entity.id > 0 ? this.translateService.instant('ALERT_UPDATE_HEADER') : this.translateService.instant('ALERT_ADD_HEADER');

    Swal.fire({
      title: titleText,
      text: this.translateService.instant('ALERT_CONFIRM_TEXT'),
      showClass: {
        popup: 'animated fadeInDown faster',
      },
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('BTN_CONTINUE'),
      cancelButtonText: this.translateService.instant('BTN_CANCEL'),
    }).then((result) => {

      if (result.isConfirmed) {
        this.doSave();
      }
    })
  }


  private doSave() {

    this.setFormDataValuesToEntity();

    const saveObs$ = (this.entity.id === 0) ? this.cardService.add(this.entity) : this.cardService.update(this.entity);
    const findByIdObs$ = (id: number) => this.cardService.findById(id);


    this.save(saveObs$, findByIdObs$);

  }

  protected override setTransientValuesToFormGroup() {
    this.formGroup.controls['customerName'].setValue(this.entity.customer?.name);
    this.formGroup.controls['cardGroupName'].setValue(this.entity.cardGroup?.serial);


  }
  // #endregion --------------------------------------------------------------------
  // -------------------------------------------------------------------------------

}
