
<div class="nk-block">
  <div class="row gy-4 mb-3">

    <div class="col-xxl-9 col-md-9">

      <div class="row gy-4">

        <div class="col-xxl-3 col-md-6 col-lg-3">
          <div class="form-group">
            <label class="form-label" for="code">Código</label>
            <div class="form-control-wrap">
                <input type="text" id="code" name="code" class="form-control form-control-readonly" readonly value="{{ entity.code }}">
            </div>
          </div>
        </div><!--col-->

        <div class="col-xxl-9 col-md-6 col-lg-9">
          <div class="form-group">
            <label class="form-label" for="uid">Uid</label>
            <div class="form-control-wrap">
                <input type="text" id="uid" name="uid" class="form-control form-control-readonly" readonly value="{{ entity.uid }}">
            </div>
          </div>
        </div><!--col-->

        <div class="col-md-12 col-lg-12">
          <div class="form-group">
            <label class="form-label" for="customerName">Cliente:</label>
            <div class="form-control-wrap">
                <input type="text" id="customerName" name="customerName" class="form-control form-control-readonly" readonly value="{{ entity.customer?.name }}">
            </div>
          </div>
        </div><!--col-->

        <div class="col-xxl-6 col-md-6 col-lg-6">
          <div class="form-group">
            <label class="form-label" for="model">Modelo</label>
            <div class="form-control-wrap">
                <input type="text" id="model" name="model" class="form-control form-control-readonly" readonly value="{{ entity.model }}">
            </div>
          </div>
        </div><!--col-->

        <div class="col-xxl-6 col-md-6 col-lg-6">
          <div class="form-group">
            <label class="form-label" for="group">Grupo</label>
            <div class="form-control-wrap">
                <input type="text" id="group" name="group" class="form-control form-control-readonly" readonly value="{{ entity.cardGroup.name }}">
            </div>
          </div>
        </div><!--col-->

        <div class="col-md-12">
          <div class="form-group">
            <label class="form-label" for="description">Descripción:</label>
            <div class="form-control-wrap">
                <input type="text" id="description" name="description" class="form-control form-control-readonly" readonly value="{{ entity?.description }}">
            </div>
          </div>
        </div><!--col-->

      </div><!--row-->

    </div><!--col-->

    <div class="col-xxl-3 col-md-3">
      <div class="form-group">
        <label class="form-label" for="uid">Código QR</label>
        <div class="card card-bordered">
            <img class="card-img-top" [src]="urlForQRCode" alt="Código QR">
        </div>
      </div>
    </div><!--col-->

  </div><!--row-->
</div><!--block-->


<div class="nk-block">
  <div class="row gy-2">
    <div class="col-xxl-6 col-md-6">
      <div class="form-group">
        <button (click)="gotoEditPage($event)" class="btn btn-primary">
          {{ 'BTN_EDIT' | translate }}
        </button>
      </div>
    </div><!--col-->
  </div><!--row-->
</div><!--block-->



