// Angular modules
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';

// External modules
import { Observable, delay, of, map, catchError } from 'rxjs';

// Services
import { CardService } from '../services/card.service';

// Models
import { QueryParameters } from '@models/index';
import { QueryResults } from '@models/api-request.model';
import { Card } from '../models';

// Environment
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class UniqueCardCodeValidator {

  constructor(
    private cardService: CardService,
  ) { }


  validateUniqueCardNameValidator(entityId: number): AsyncValidatorFn {
    return (control: AbstractControl<any, any>): Observable<ValidationErrors | null> => {

      const queryParamsToQuery = this.getQueryParamsPrepared(control.value);

      return this.cardService.find(queryParamsToQuery).pipe(
        delay(environment.delayFake),
        map((resp: QueryResults<Card>) => {
          if (resp?.items?.length === 0) {
            return null; // not exits any entity with same 'term'
          } else {
            let cardCodeExists;

            // array with all entity ids that match with query, that is 'with same term'
            const entityIdsWithSameTerm = resp.items.map(
              (entity: Card) => entity.id
            );

            if (entityId === 0) {
              // is new entity (entityId===0) and exists others entities with same term

              cardCodeExists = true;
            } else {
              // is an existinng entity

              //true: if entityId is not included in entityIdsWithSameTerm -> other entity has the same term
              cardCodeExists = !entityIdsWithSameTerm?.includes(entityId);

              if (!cardCodeExists) return null;
            }
            return { cardCodeExists, entityIdsWithSameTerm };
          }
        }),
        catchError(() => of([]))
      );
    };
  }


  private getQueryParamsPrepared(value: any): QueryParameters {

    const toret: QueryParameters = {
      pageSize: 1, // only one result in response is needed to check if a card code exits
      filterByParams: {
        name: value
      }
    }

    return toret;

  }


  registerOnValidatorChange?(fn: () => void): void {
    throw new Error('Method not implemented.');
  }
}
