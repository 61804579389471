// Angular modules
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

// External modules
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from '@services/store.service';
import { NGXLogger } from 'ngx-logger';

// Helpers
import { CardFormGroupHelper } from '@cards/cards-mgmt/helpers/card-form-configurer.helper';

// Models
import { Card } from '@cards/cards-mgmt/models';

// Modal
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// Base class
import { BaseQuickviewComponent } from '@pages/base/base-quickview.component';
import { CardService } from '@cards/cards-mgmt/services/card.service';
import { EventBusService } from '@services/event-bus.service';

@Component({
  selector: 'lockbin-card-quickview',
  templateUrl: './card-quickview.component.html',
  styles: [
  ]
})
export default class CardQuickviewComponent extends BaseQuickviewComponent<Card> implements OnInit {

  @Input() data: Card | undefined;
  @Output() submitData: EventEmitter<any> = new EventEmitter();
  @Output() submitClose: EventEmitter<null> = new EventEmitter();

  urlForQRCode= '';


  // -------------------------------------------------------------------------------
  // NOTE Constructor & Init -------------------------------------------------------
  // -------------------------------------------------------------------------------
  constructor(
    protected override location: Location,
    protected override titleService: Title,
    protected override storeService: StoreService,
    protected override router: Router,
    protected override logger: NGXLogger,
    protected override translateService: TranslateService,
    protected override activeModal: NgbActiveModal,
    protected override activatedRoute: ActivatedRoute,
    protected override eventBusService: EventBusService,
    protected cardService: CardService

  ) {

    super(location, titleService, storeService, router, logger, translateService, activeModal, activatedRoute, eventBusService);

  }

  override ngOnInit(): void {

    if (!this.data) return;

    this.entity = this.data;

    this.urlForQRCode= this.cardService.getUrlForQRCode(this.entity);

    this.setupRoutes();
  }

  /**
   * Set routes to list/edit after page operation (add or edit) is done
   */
  private setupRoutes() {
    this.routeToEdit = '/tarjetas/gestion-tarjetas/edit/';
  }






}
