// Angular modules
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

// Base class
import { BaseAddComponent } from '@pages/base/base-add.component';

// Enums
import { BUS_EVENT_TYPE } from '@enums/bus-event-type.enum';

// Components
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

// Models
import { Card } from '@cards/cards-mgmt/models/card.model';
import { CardImportResult } from '@cards/cards-mgmt/models';

// Services
import { StoreService } from '@services/store.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { EventBusService } from '@services/event-bus.service';



@Component({
  selector: 'lockbin-card-import',
  templateUrl: './card-import.component.html',
  styles: ``
})
export class CardImportComponent extends BaseAddComponent<Card> implements OnInit {

  activeFormTab = 1;
  actualStep = 1;
  mostAdvancedStep = 1;

  resultsFromStep1ToStep2: CardImportResult = new CardImportResult();

  // -------------------------------------------------------------------------------
  // #region Constructor & Init ----------------------------------------------------
  // -------------------------------------------------------------------------------
  constructor(
    protected override location: Location,
    protected override titleService: Title,
    protected override storeService: StoreService,
    protected override router: Router,
    protected override logger: NGXLogger,
    protected override translateService: TranslateService,
    protected override activatedRoute: ActivatedRoute,
    protected override eventBusService: EventBusService,

  ) {

    super(location, titleService, storeService, router, logger, translateService, activatedRoute, eventBusService);
  }


  override ngOnInit(): void {

    this.storeService.setIsLoading(true);

    // initializates subscriptions to bus event
    this.initSubscriptionsToBusService();

    this.storeService.setIsLoading(false);
  }

  // #endregion --------------------------------------------------------------------
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Subscriptions to EventBus  --------------------------------------------
  // -------------------------------------------------------------------------------
  private initSubscriptionsToBusService() {

    // step 1 done
    this.eventBusService.on(BUS_EVENT_TYPE.CARD_IMPORT_STEP_1_DONE.toString(),
      (results: CardImportResult) => this.handBookingImportStep1Done(results));

    // step 1 fails
    this.eventBusService.on(BUS_EVENT_TYPE.CARD_IMPORT_STEP_1_FAIL.toString(),
      (err: any) => this.handBookingImportFails(err));
  }


  private handBookingImportStep1Done(resultsImport: CardImportResult) {

    this.activeFormTab = 2;
    this.actualStep = this.activeFormTab;
    this.mostAdvancedStep = 2;

    this.resultsFromStep1ToStep2 = resultsImport;
  }


  handBookingImportFails(err: any) {
    this.showErrortMsg(`Ha fallado el proceso debido a ${err?.error?.error}`);
  }
  // #endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Actions ---------------------------------------------------------------
  // -------------------------------------------------------------------------------

  public onTabChange(event: NgbNavChangeEvent) {
    this.actualStep = event.nextId;
  }

  // #endregion
  // -------------------------------------------------------------------------------

}

