
<div class="nk-block-head nk-block-head-sm">
  <div class="nk-block-between">
      <div class="nk-block-head-content">
          <h3 class="nk-block-title page-title">Importador de Tarjetas de Ciudadanos</h3>
          <div class="nk-block-des text-soft">
            <p>Siga los pasos indicados en el formulario para importar el lote de tarjetas desde ficheros externos.</p>
          </div>
      </div>
      <div class="nk-block-head-content me-2">
        <button (click)="goBack()" class="btn btn-outline-light bg-white">
          <em class="icon ni ni-arrow-left"></em>
          <span>{{ 'BTN_BACK' | translate }}</span>
        </button>
      </div>
  </div>
</div>

<lockbin-alert [isFilled]="false" [dismissible]="true"></lockbin-alert>

<div class="nk-block">
  <div class="card card-bordered">
    <div class="card-inner-group">

      <div class="card-inner pt-0">
        <div class="card-content pb-2">

          <ul ngbNav #formTab="ngbNav"
            (navChange)="onTabChange($event)"
            [(activeId)]="activeFormTab" class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">

            <!----------------------------------------------->
            <!------------------- TAB 1 --------------------->
            <!-- File selection -->
            <li [ngbNavItem]="1" class="nav-item">
              <a ngbNavLink><em class="icon ni ni-upload"></em> <span>Paso 1: Fichero</span></a>
              <ng-template ngbNavContent>

                <lockbin-step1-card-import>
                </lockbin-step1-card-import>

              </ng-template>
            </li><!-- tab1 -->
            <!----------------------------------------------->

            <!----------------------------------------------->
            <!------------------- TAB 2 --------------------->
            <!-- PreImport revisión -->
            <li [ngbNavItem]="2" [disabled]="this.mostAdvancedStep<2">
              <a ngbNavLink><em class="icon ni ni-done"></em> <span>Paso 2: Resultado</span></a>
              <ng-template ngbNavContent>

                <ng-container>

                  <lockbin-step2-card-import
                    [resultImport]="resultsFromStep1ToStep2">
                  </lockbin-step2-card-import>

                </ng-container>

              </ng-template>
            </li><!-- tab2 -->
            <!----------------------------------------------->


          </ul>
          <div [ngbNavOutlet]="formTab"></div>

        </div><!--card-content-->
      </div><!--card-inner pt-0-->

    </div><!--card-inner-group-->
  </div><!-- card card-bordered -->
</div><!-- .nk-block -->
