// Angular modules
import { Component, Input, OnInit, ViewChild } from '@angular/core';

// Components
import { AlertComponent } from '@blocks/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalWrapperComponent } from '@modals/modal-wrapper/modal-wrapper.component';
import { ResultCardImportQuickviewComponent } from '@cards/cards-mgmt/pages/result-card-import-quickview/result-card-import-quickview.component';

// Exporter
import { ExcelExporterHelper } from '@excel-exporter/excel-exporter.helper';
import { ExporterHelper } from '@helpers/exporter.helper';

// Models
import { CardImportResult } from '@cards/cards-mgmt/models/card-import-result.model';
import { CardExporter } from '@cards/cards-mgmt/models';

// Services
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from '@services/store.service';
import { ModalsOptionsService } from '@services/modalsOptions.service';
import { NGXLogger } from 'ngx-logger';


@Component({
  selector: 'lockbin-step2-card-import',
  templateUrl: './step2-card-import.component.html',
  styles: ``
})
export class Step2CardImportComponent implements OnInit {

  btnDownloadLabelOK = '';
  btnDownloadLabelKO = '';

  @Input() resultImport!: CardImportResult;

  @ViewChild(AlertComponent) alertComponent!: AlertComponent;

  // -------------------------------------------------------------------------------
  // #region Constructor & Init ----------------------------------------------------
  // -------------------------------------------------------------------------------
  constructor(
    private modalService: NgbModal,
    private modalsOptionsService: ModalsOptionsService,
    protected storeService: StoreService,
    protected translateService: TranslateService,
    protected logger: NGXLogger,

  ) { }


  ngOnInit(): void {
    this.btnDownloadLabelOK = this.translateService.instant('BTN_DOWN_CARD_OK');
    this.btnDownloadLabelKO = this.translateService.instant('BTN_DOWN_CARD_KO');
  }
  // #endregion --------------------------------------------------------------------
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Modal -----------------------------------------------------------------
  // -------------------------------------------------------------------------------
  showModalQuickView(item: unknown) {

    const modalRef = this.modalService.open(ModalWrapperComponent, this.modalsOptionsService.getDefaultModalOptionsMd());

    modalRef.componentInstance.component = ResultCardImportQuickviewComponent;
    modalRef.componentInstance.componentData = item;
    modalRef.componentInstance.modalData = {
      title: 'Resultados de la Importación',
      useHeader: true,
      headerClasses: '',
      closable: true,
    };
  }
  // #endregion --------------------------------------------------------------------
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Utils -----------------------------------------------------------------
  // -------------------------------------------------------------------------------
  getTooltipText(arr: string[], maxLength: number) {

    const str = arr.join('. ').slice(0, maxLength);

    if (str.length >= maxLength) str.concat('...');

    return str;
  }
  // #endregion --------------------------------------------------------------------
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Export Setup to Excel -------------------------------------------------------
  // -------------------------------------------------------------------------------

  protected doExportClickOK() {

    const items = this.resultImport.cardsImported;

    const itemsExportables = ExporterHelper.getCollectionToExport(items, new CardExporter());

    ExcelExporterHelper.exportToExcel(
      itemsExportables,
      'Tarjetas-Importados',
      'Hoja 1'
    );

  }

  protected doExportClickKO() {

    const items = this.resultImport.pendingImports;

    const itemsExportables = ExporterHelper.getCollectionToExport(items, new CardExporter());

    ExcelExporterHelper.exportToExcel(
      itemsExportables,
      'Tarjetas-NO-Importados',
      'Hoja 1'
    );
  }
  // #endregion --------------------------------------------------------------------
  // -------------------------------------------------------------------------------

}

