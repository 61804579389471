
<h5 class="title nk-block-title">Paso 2: Importación finalizada</h5>


<div class="card card-bordered mb-3">
  <div class="card-inner">
    <div class="row">
      <div class="col-md-8">
        <p class="fs-16px">Se han importado correctamente
          <span class="text-primary fw-medium">{{ resultImport.cardsImported.length }} tarjetas</span>.
        </p>
      </div>
      <div class="col-md-4">
        <lockbin-btn-export
        [buttonLabel] = "this.btnDownloadLabelOK"
        [numDataToExport]= "resultImport.cardsImported.length"
        (doClick)="doExportClickOK()">
      </lockbin-btn-export>
    </div>
  </div>
</div>
</div>

<div class="card card-bordered mb-3">
  <div class="card-inner">
    <div class="row">
      <div class="col-md-8">
        <p class="fs-16px"><span class="text-primary fw-medium">NO</span> se han podido importar las
          <span class="text-primary fw-medium">{{ resultImport.pendingImports.length }} tarjetas</span> que se muestran en la tabla.
        </p>
      </div>
      <div class="col-md-4">
        <lockbin-btn-export
        [buttonLabel] = "this.btnDownloadLabelKO"
        [numDataToExport]="this.resultImport.pendingImports.length"
        (doClick)="doExportClickKO()">
      </lockbin-btn-export>
    </div>
  </div>
</div>
</div>



<div class="nk-block">
  <div class="card card-bordered border-bottom-0 card-stretch">

    <div class="card-inner p-0">

      <ngx-simplebar class="table-form--sticky-header" [options]="{ autoHide: true }" [minHeight]="180" [dynamicHeight]="false">

        <div class="nk-tb-list nk-tb-ulist">

          <div class="nk-tb-item nk-tb-head">

            <div class="nk-tb-col">
              <span class="sub-text">Nº Serie</span>
            </div>
            <div class="nk-tb-col">
              <span class="sub-text">UUID</span>
            </div>
            <div class="nk-tb-col">
              <span class="sub-text">Mensajes</span>
            </div>
          </div>

          <ng-container *ngIf="this.storeService.getIsLoading()">
            <div class="card-inner p-0">
              <div class="spinner-grow" role="status">  <span class="visually-hidden">Cargando...</span></div>
            </div>
          </ng-container>

          <ng-container *ngIf="!this.storeService.getIsLoading()">
            <div class="nk-tb-item" *ngFor="let dataItem of this.resultImport.pendingImports">

              <div class="nk-tb-col">
                <lockbin-cell-basic (doClick)="showModalQuickView(dataItem)" [hasAction]="true">
                  {{ dataItem.serial }}
                </lockbin-cell-basic>
              </div>
              <div class="nk-tb-col">
                <lockbin-cell-basic [hasAction]="false">
                  {{ dataItem.uuid }}
                </lockbin-cell-basic>
              </div>
              <div class="nk-tb-col">
                <lockbin-cell-basic (doClick)="showModalQuickView(dataItem)" [hasAction]="true">
                  <span class="badge badge-dim bg-warning" *ngIf="dataItem.warnings.length>0" ngbTooltip="{{this.getTooltipText(dataItem.warnings, 60)}}">
                    <em class="icon ni ni-alert"></em><span>{{ dataItem.warnings.length }} alertas</span>
                  </span>
                  <span class="badge badge-dim bg-danger" *ngIf="dataItem.errors.length>0" ngbTooltip="{{this.getTooltipText(dataItem.errors, 60)}}">
                    <em class="icon ni ni-alert-circle"></em><span>{{ dataItem.errors.length }} errores</span>
                  </span>
                </lockbin-cell-basic>
              </div>
            </div>
          </ng-container>

        </div>

      </ngx-simplebar>

    </div>
  </div>
