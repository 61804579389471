<div class="nk-block-head nk-block-head-sm">
  <div class="nk-block-between">
    <div class="nk-block-head-content">
      <h3 class="nk-block-title page-title">{{ this.operationTitle }}</h3>
      <div class="nk-block-des text-soft">
        <p>{{ this.operationSubtitle }}</p>
      </div>
    </div><!-- .nk-block-head-content -->
  </div><!-- .nk-block-between -->
</div><!-- .nk-block-head -->

<lockbin-alert [isFilled]="false" [dismissible]="true"></lockbin-alert>

<!-- NOTE form -->
<form [formGroup]="formGroup" autocomplete="on">
  <div class="nk-block">
    <div class="card card-bordered">
      <div class="card-inner-group">
        <div class="card-inner">
          <div class="nk-block-head">
            <div class="nk-block-head-content">
              <h5 class="title nk-block-title">Datos Básicos</h5>
              <!-- <p>Add common infomation like Name, Email etc </p> -->
            </div>
          </div>
          <div class="nk-block">
            <div class="row gy-4">
              <div class="col-xxl-9 col-md-9">

                <div class="row gy-4">
                  <div class="col-xxl-6 col-md-6">
                    <div class="form-group">
                      <label class="form-label" for="code">Código</label>
                      <div class="form-control-wrap">
                        <input type="text" class="form-control" id="code" placeholder="Código" formControlName="code"
                          [ngClass]="{ 'is-invalid' : hasErrorDueToCardCodeExists() && formGroup.controls.code.touched }">

                        <div class="invalid-feedback" *ngIf="formGroup.controls.code.hasError('required')">
                          {{ 'FIELD_REQUIRED' | translate }}
                        </div>
                        <div class="invalid-feedback" *ngIf="hasErrorDueToCardCodeExists()">
                          {{ 'FIELD_NAME_EXISTS' | translate }}
                        </div>
                      </div>
                    </div>
                  </div><!--col-->
                  <div class="col-xxl-6 col-md-6">
                    <div class="form-group">
                      <label class="form-label" for="uid">Uid</label>
                      <div class="form-control-wrap">
                        <input type="text" class="form-control" id="uid" placeholder="Uid" formControlName="uid">

                        <div class="invalid-feedback" *ngIf="formGroup.controls.uid.hasError('required')">
                          {{ 'FIELD_REQUIRED' | translate }}
                        </div>
                      </div>
                    </div>
                  </div><!--col-->
                  <div class="col-xxl-6 col-md-6">
                    <div class="form-group">
                      <label class="form-label" for="model">Modelo</label>
                      <div class="form-control-wrap">
                        <input type="text" class="form-control" id="model" placeholder="Modelo" formControlName="model">

                        <div class="invalid-feedback" *ngIf="formGroup.controls.model.hasError('required')">
                          {{ 'FIELD_REQUIRED' | translate }}
                        </div>
                      </div>
                    </div>
                  </div><!--col-->

                  <div class="col-xxl-6 col-md-6">
                    <lockbin-custom-autocomplete id="customAutocompleteCardGroup" #customAutocompleteCardGroup
                      (onSelectedItem)="doSelectItemAutocompleteCardGroup($event)" [searcher]="searcherCardGroup$"
                      labelInputText="Grupo de Tarjetas" placeholderText="Buscar grupo de tarjetas..."
                      fieldToFilter="name" fieldToShow="name">
                    </lockbin-custom-autocomplete><!--custom autocomplete-->
                  </div><!--col-->

                  <div class="col-xxl-12 col-md-12">
                    <div class="form-group">
                      <label class="form-label" for="description">Description</label>
                      <div class="form-control-wrap">
                        <input type="text" class="form-control" id="description" placeholder="Description"
                          formControlName="description">

                        <div class="invalid-feedback" *ngIf="formGroup.controls.description.hasError('required')">
                          {{ 'FIELD_REQUIRED' | translate }}
                        </div>
                      </div>
                    </div>
                  </div><!--col-->
                  <div class="col-xxl-8 col-md-8" *ngIf="this.canCustomerBeEdited">
                    <lockbin-custom-autocomplete id="customAutocompleteCustomer"
                      #customAutocompleteCustomer (onSelectedItem)="doSelectItemAutocompleteCustomer($event)"
                      [searcher]="searcherCustomer$" labelInputText="Cliente" placeholderText="Buscar cliente..."
                      fieldToFilter="name" fieldToShow="name">
                    </lockbin-custom-autocomplete><!--custom autocomplete-->
                  </div><!--col-->

                </div><!--row-->

              </div>
              <div class="col-xxl-3 col-md-3" *ngIf="this.entity?.id>0">
                <div class="form-group">
                  <label class="form-label" for="uid">Código QR</label>
                  <div class="card card-bordered">
                    <img class="card-img-top" [src]="urlForQRCode" alt="Código QR">
                  </div>
                </div>
              </div><!-- .col -->
            </div>
          </div>
        </div><!-- .card-inner -->

        <div class="card-inner">
          <div class="nk-block">
            <div class="row gy-4">
              <div class="col">

                <div class="d-flex justify-content-between">
                  <button (click)="onClickSubmit()" class="btn btn-primary"
                    [lockbinButtonLoading]="this.formGroup.pending || this.storeService.getIsLoading()"
                    [disabled]="formGroup.pending || formGroup.invalid">
                    <span>{{ 'BTN_SAVE' | translate }}</span>
                    <em class="icon ni ni-save"></em>
                  </button>
                  <button (click)="goBack()" class="btn btn-outline-light bg-white">
                    <em class="icon ni ni-arrow-left"></em>
                    <span>{{ 'BTN_BACK' | translate }}</span>
                  </button>
                </div>

              </div>
            </div><!--row-->
          </div>
        </div><!-- .card-inner -->
      </div>
      <div *ngIf="formGroup.invalid">
        <div *ngIf="formGroup.controls.code.errors && formGroup.controls.code.touched">
          {{ 'FIELD_REQUIRED' | translate }} ---
        </div>
      </div>
    </div><!-- .card -->

  </div><!-- .nk-block -->
</form>

<div class="code-debug" *ngIf="!environment.production">
  <code class="debug">
    <p>Form Valid: {{ formGroup.valid }}</p>
    <p>Form Invalid: {{ formGroup.invalid }}</p>
    <p>Form Status: {{ formGroup.status }}</p>
    <p>Form Pending: {{ formGroup.pending }}</p>
    <p>Form errors</p>

    <pre>{{ formGroup.errors | json }}</pre>

    <p>Nombre</p>
    <pre>{{ formGroup.controls.code.value | json }}</pre>
    <pre>{{ formGroup.controls.code.errors | json }}</pre>

    <p>Errores del formulario</p>
    <pre>{{ formGroup.errors | json }}</pre>
  </code>
</div>
