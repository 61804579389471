// Models
import { CardGroup } from "@cards/card-groups/models";
import { Customer } from "@customers/customers-mgmt/models";
import { QueryFilter } from "@models/api-request.model";
import { Exportable, Exporter } from "@models/index";

export class Card implements Exportable {


  id:            number = 0;
  version:       number | undefined;
  code:          string | undefined;
  uid:           string | undefined;
  model:         string | undefined;
  description:   string | undefined;
  customerId:    number | undefined;
  cardGroupId:   number | undefined;

  customer:      Customer = new Customer();
  cardGroup:     CardGroup = new CardGroup();


  constructor(){}

}

export class CardFilter implements QueryFilter {

  code:      string | undefined;
  uid:       string | undefined;
  model:     string | undefined;

}


export class CardExporter implements Exporter  {

  code;
  uid;
  model;
  description;
  customer;
  cardGroup;


  constructor() {

    this.code= {
      nameToShow: 'Código'
    };
    this.uid = {
      nameToShow: 'Uid'
    };

    this.model = {
      nameToShow: 'Modelo'
    };

    this.description = {
      nameToShow: 'Descripción'
    };

    this.customer = {
      name: {
        nameToShow: 'Cliente'
      }
    };

    this.cardGroup = {
      name: {
        nameToShow: 'Grupo de Tarjetas'
      }
    };

  }
}




