// Angular modules
import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Injectable()
export class CardFormGroupHelper {

  constructor(
  ) {

  }

  public getCardFormGroup(): FormGroup {

      return new FormGroup({
        code: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.required, Validators.maxLength(255)],
            nonNullable: true,
            asyncValidators: [],
            updateOn: 'change',
          }
        ),
        uid: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.required, Validators.maxLength(255)],
            nonNullable: true,
            updateOn: 'change',
          }
        ),
        model: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.maxLength(255)],
            nonNullable: true,
            updateOn: 'change',
          }
        ),
        description: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.maxLength(255)],
            nonNullable: true,
            updateOn: 'change',
          }
        ),
        customerId: new FormControl<number | undefined>(
          { value: undefined, disabled: false },
          {
            validators: [],
            nonNullable: true,
            updateOn: 'change',
          }
        ),
        cardGroupId: new FormControl<number | undefined>(
          { value: undefined, disabled: false },
          {
            validators: [],
            nonNullable: true,
            updateOn: 'change',
          }
        ),
        customerName: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.maxLength(255)],
            nonNullable: true,
            updateOn: 'change',
          }
        ),
        cardGroupName: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.maxLength(255)],
            nonNullable: true,
            updateOn: 'change',
          }
        ),

    })
  }
}


