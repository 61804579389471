// Angular modules
import { Injectable } from '@angular/core';

import { DataListConfigurerHelper } from '@helpers/datalist-configurer.helper';
import { Card } from '../models';
import {
  DataListOperation,
  DataListHeader,
  DataListHeaderItem,
  ResponsiveBreakpoints,
  DataListPagination,
  DataListRowOperationAlias,
  DataList,
  DataListItem
} from '@models/index';

@Injectable()
export class CardDataListConfigurerHelper extends DataListConfigurerHelper<Card>{

  public getDefaulDataList(): DataList<DataListItem<Card>> {

    const toret: DataList<DataListItem<Card>> = new DataList();

    toret.searchPlaceholder= 'Buscar por nombre';
    toret.name = '------TITULO-----------';
    toret.useCheckbox = true;
    toret.useSearch = true;

    return toret;
  }

  public getDefaulDataListHeader(): DataListHeader {

    const toret: DataListHeader = new DataListHeader();

    toret.canToggleItems = true;

    toret.pagination = new DataListPagination();
    toret.pagination.itemsPerPage = 10;

    toret.headers = this.getDefaultDataListHeaderItems();

    return toret;
  }

  public getDefaultDataListHeaderItems(): DataListHeaderItem[] {
    const toret: DataListHeaderItem[] = [
      {
        alias: 'code',
        title: 'Código',
        sortable: true,
        visible: true,
      },
      {
        alias: 'uid',
        title: 'Uid',
        sortable: true,
        visible: true,
      },
      {
        alias: 'model',
        title: 'Modelo',
        sortable: true,
        visible: true,
      },
      {
        alias: 'description',
        title: 'Descripción',
        sortable: true,
        visible: true,
      },
      {
        alias: 'customer',
        title: 'Cliente',
        sortable: true,
        visible: true,
      },
      {
        alias: 'cardGroup',
        title: 'Grupo de Tarjetas',
        sortable: true,
        visible: true,
      },
    ]

    return toret;
  }

  public getDefaultDataListOperations(): DataListOperation[] {
    return [
      {
        alias: DataListRowOperationAlias.ViewDetails,
        icon: 'eye',
        title: 'Ver detalle',
      },
      {
        alias: DataListRowOperationAlias.Edit,
        icon: 'pen',
        title: 'Modificar',
      },
      {
        alias: DataListRowOperationAlias.Delete,
        icon: 'na',
        title: 'Deshabilitar',
      },
    ];
  }

  public getDefaultBulkOperations(): DataListOperation[] {
    return [
        // {
        // "alias": DataListRowOperationAlias.Email,
        //   "title": "Send Email",
        // },
        // {
        //   "alias": DataListRowOperationAlias.ChangeGroup,
        //   "title": "Change Group",
        // },
        // {
        //   "alias": DataListRowOperationAlias.Suspend,
        //   "title": "Suspend User",
        // },
        {
          "alias": DataListRowOperationAlias.Delete,
          "title": "Deshabilitar",
        },
      ]
  }

}
