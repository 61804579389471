// Angular Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

// External Modules
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';

// Models
import { QueryParameters, QueryResults } from '@models/index';
import { Card, CardImportResult } from '@cards/cards-mgmt/models/index';
import { Customer } from '@customers/customers-mgmt/models';
import { CardGroup } from '@cards/card-groups/models';

// Services
import { HttpQueryUtils } from '@services/http-query-utils.service';
import { CustomerService } from '@customers/customers-mgmt/services/customer.service';
import { CardGroupService } from '@cards/card-groups/services/card-group.service';
import { SearchStrategy } from '@enums/search-strategy.enum';

const BACKEND_API = `${environment.apiBaseUrl}`;
const ENTITY = 'card'


@Injectable({
  providedIn: 'root'
})
export class CardService {

  constructor(
    private http: HttpClient,
    public httpQueryUtils: HttpQueryUtils,
    private customerService: CustomerService,
    private cardGrouService: CardGroupService) { }

  // get httpParams() {
  //   return new HttpParams()//.set('fields', 'name,capital,alpha2Code,flags.png,population');
  // }


  find(queryParameters: QueryParameters): Observable<QueryResults<Card>> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    const queryResults$ =  this.httpQueryUtils.executeQuery<Card>( url, queryParameters)
      .pipe(
        switchMap((queryResults: QueryResults<Card>) => {

          // return empty Observable in case of no items
          if( queryResults.items.length === 0 ) return of(queryResults);

          return this.fillRelatedAttributes(queryResults.items)
            .pipe(
              map(() => queryResults))
        })
      )

    return  queryResults$;
  }

  findById(id: number, strategy?: SearchStrategy): Observable<Card> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;

    let queryResults$ = this.httpQueryUtils.executeQuery<Card>(url, {})
    .pipe(
      map(result => result.items[0])
    );

    if (strategy === SearchStrategy.EAGER) {

      queryResults$ = queryResults$
        .pipe(
          switchMap((itemParent: Card) => {

            return this.fillRelatedAttributes([itemParent])
              .pipe(
                map((items) => items[0]))
          })
        )
    }
    return queryResults$;

  }

  private fillRelatedAttributes(items: Card[]) {

    const requestDetail_1: Observable<Customer>[] = this.getDetailObservablesCustomer(items);
    const requestDetail_2: Observable<CardGroup>[] = this.getDetailObservablesCardGroup(items);

    // la caña de España!
    return forkJoin([...requestDetail_1, ...requestDetail_2])
      .pipe(
        map((arrayWithDetailData: any) => {

          const resultDetail_1 = arrayWithDetailData.slice(0, items.length);
          const resultDetail_2 = arrayWithDetailData.slice(items.length);

          items
            .map((item, i) => {
              item.customer = resultDetail_1[i];
              item.cardGroup = resultDetail_2[i];
              return item;
            });


          return items;
        })
      );
  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesCustomer(items: Card[]): Observable<Customer>[] {

    return items.map((item: Card) => {

      if (item.customerId && item.customerId>0) {
        return this.customerService.findById(item.customerId)
      } else {
        return of(new Customer());
      }

    });

  }

  /**
   *
   * @param items array of items
   * @returns Array of Observables for get detailed data (findById)
   */
  private getDetailObservablesCardGroup(items: Card[]): Observable<CardGroup>[] {

    return items.map((item: Card) => {

      if (item.cardGroupId && item.cardGroupId > 0) {
        return this.cardGrouService.findById(item.cardGroupId)
      } else {
        return of(new CardGroup());
      }

    });

  }


  delete(id: number): Observable<any> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;

    return this.http.delete(url);
  }


  deleteBulk(ids: Array<number>): Observable<any> {

    const colObservablesToDelete= [];

    for (const id of ids) {
      colObservablesToDelete.push(this.delete(id));
    }

    return forkJoin( colObservablesToDelete );
  }


  add(entity: Card): Observable<Card> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    return this.http.post<Card>(url, entity);
  }


  update(entity: Card): Observable<Card> {

    const url = `${BACKEND_API}/${ENTITY}/${entity.id}/`;

    return this.http.put<Card>(url, entity);
  }


  /**
   * IMPORT
   * @param file
   * @returns Observable<CardImportResult>
   */
  import(file: File): Observable<CardImportResult> {

    const url = `${BACKEND_API}/${ENTITY}/import/`;

    const formData = new FormData();
    formData.append('multipartFile', file, file.name);

    // const headers = new HttpHeaders({
    //   'Content-Type': 'multipart/form-data' // Establecer el tipo de contenido como multipart/form-data
    // });

    const requestOptions = {
      // headers: headers,
      withCredentials: true,
    };

    return this.http.post<CardImportResult>(url, formData, requestOptions);

  }


  getUrlForQRCode(entity: any) {

    if(entity?.id > 0) {
      return `https://api.backend.lockbin.es/api/backend/card/qrcode/${entity.id}/`;
    }

    return '';
  }

}
