// Angular modules
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// External modules
import Swal from 'sweetalert2';

// Enums
import { BUS_EVENT_TYPE } from '@enums/bus-event-type.enum';

// Models
import { CardImportResult } from '@cards/cards-mgmt/models/card-import-result.model';

// Services
import { EventBusService } from '@services/event-bus.service';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from '@services/store.service';
import { UtilsService } from '@services/utils.service';
import { CardService } from '@cards/cards-mgmt/services/card.service';


@Component({
  selector: 'lockbin-step1-card-import',
  templateUrl: './step1-card-import.component.html',
  styles: ``
})
export class Step1CardImportComponent implements OnInit {


  @Input() resultImport!: CardImportResult;
  @ViewChild('multipartFile') multipartFile!: ElementRef;


  // -------------------------------------------------------------------------------
  // #region Defined reactive form on Angular style ----------------------------
  // -------------------------------------------------------------------------------
  formGroup!: FormGroup<{
    multipartFile: FormControl;
  }>;
  // #endregion
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Constructor & Init ----------------------------------------------------
  // -------------------------------------------------------------------------------
  constructor(
    protected storeService: StoreService,
    // private partnrService: PartnerService,
    private eventBusService: EventBusService,
    private translateService: TranslateService,
    private utilsService: UtilsService,
    private cardService: CardService
  ) { }


  ngOnInit(): void {

    this.formGroup = this.getFormGroup();

  }

  private getFormGroup() {
    return new FormGroup({
      multipartFile: new FormControl<File | null>(
        { value: null, disabled: false },
        Validators.required
      )
    });
  }


  // #endregion --------------------------------------------------------------------
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region File Event Handlers -----------------------------------------------
  // -------------------------------------------------------------------------------

  onFileSelected() {

    this.formGroup.controls['multipartFile'].setValue(this.multipartFile.nativeElement.files[0]);

  }

  // #endregion --------------------------------------------------------------------
  // -------------------------------------------------------------------------------


  // -------------------------------------------------------------------------------
  // #region Save And Next Step ----------------------------------------------------
  // -------------------------------------------------------------------------------

  onClickImport() {

    const titleText = `Importación de Lote de Tarjetas de Ciudadanos`;

    const html = `<p>Se tratarán de importar todos las tarjetas. Al finalizar el proceso se mostrarán los registros importados correctamente y aquellos que se lograron importar.</p>
    <p>${this.translateService.instant('ALERT_CONFIRM_TEXT')}</p>`

    Swal.fire({
      title: titleText,
      html: html,
      showClass: {
        popup: 'animated fadeInDown faster',
      },
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('BTN_CONTINUE'),
      cancelButtonText: this.translateService.instant('BTN_CANCEL'),
    }).then((result) => {

      if (result.isConfirmed) {
        this.import();
      }
    })

  }

  private import() {

    const fileToImport: File = this.formGroup.controls['multipartFile'].value;

    this.storeService.setIsLoading(true);

    this.cardService.import(fileToImport)
      .subscribe({
        next: (result => {

          this.eventBusService.emit({ name: BUS_EVENT_TYPE.CARD_IMPORT_STEP_1_DONE, value: result });

        }),
        error: (err => {
          this.eventBusService.emit({ name: BUS_EVENT_TYPE.CARD_IMPORT_STEP_1_FAIL, value: err });
          this.storeService.setIsLoading(false);
        }),
        complete: () => {
          this.storeService.setIsLoading(false);

        }
      })

  }

  // #endregion --------------------------------------------------------------------
  // -------------------------------------------------------------------------------

}
