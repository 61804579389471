import { Component } from '@angular/core';

@Component({
  selector: 'lockbin-result-card-import-quickview',
  templateUrl: './result-card-import-quickview.component.html',
  styles: ``
})
export class ResultCardImportQuickviewComponent {

}
